/**!
 *  PAGER
 *
 *  @name			      pager.js (js/widgets/pager.js)
 *
 *  @client	        NORDPOL-900
 *  @package        WIDGETS
 *  @copyright 	    (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
 *
 *  @description	  Widget to provide pager UI for entity-list classes (js/entities/[my_entity]_list.js)

                    Expects to be applied to HTML-elements that might look like this:

                    <nav id="PAGER_[myId]"
                        class="pager-widget navigation ..."

                        *//* mandatory:
                        data-id="[myId]"
                        data-target="#TABLE_[myId]"
                        data-entity="[my_entity]_list"

                        *//* optional: *//*
                        data-page="[currentPage]"
                        data-sticky-filter= ...
                        ...
                    ></nav>

                    This is #WIP and could (probably will) suffer some changes in the Future:

                    The Basic idea is, that all data-attributes of the element are injected into widget- and entity_list options objects, so that the pager can be applied to all entity_list classes (js/entities/*_list.js) w/ all necessary pager and entity presets (filter, order, itemsPerPage, etc.) configurable via the data-api. The id passed via data-id is used to identify unique controlling elements in the document-context:

                    '#TABLE_[myId] .sortables'
                      => on th's of sortable columns w/ data-order-by="some-param" attribute

                    '#COUNTER_[myId]'
                      => gets fed w/ @itemsTotal via $('#COUNTER_[myId]').text(@itemsTotal)

                    ...

                    The 'id' attribute of the pagers target <table/> is passed via data-target. Inside the target <table/> an element 'tbody, .tbody' is assumed to be in place, to receive the list-items HTML typically supplied as table-rows <tr/> by the entity_list class.

                    Arrays of controlling-elemnts are looked up by class (f.e. .js-filter-menu) who's data-target matche the
                    data-target of the pager.

                    ...
*
*/

import { gsap } from 'gsap';
import './csv_button';

$(function()
{
    $.widget('widgets.pager',
    {
				options: {
                      id: null,
    							entity: null,
                    list: null,
        listItemSelector: null,
    						dataType: 'text',

            // pagination
              pagination: true,
                    page: 1,
            itemsPerPage: null,
              pagesTotal: 0,
              itemsTotal: 0,

         showPageButtons: true,
          maxPageButtons: 4,

            // selectors
            clipboardSel: '.clipboard-btn',
          positionBtnSel: '.js-position-btn',

            // styles
           iconPrevClass: 'icon icon-chevron-left',
           iconNextClass: 'icon icon-chevron-right',
                  bsSize: 'sm',

                  filter: []
				},

				// The constructor
        _create: function()
        {
            $.extend(this.options, this.element.data());

            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _create`);
            if (DEBUG && VERBOSITY >= 2) console.log(this.options);

            this.element.addClass('navigation');

            // TABLE
            this.table = $('#TABLE_' + this.options.id) || false;

            // COUNTER
            this.counter = $('#COUNTER_' + this.options.id) || false;

            // FOOTER
            this.footer = $('#FOOTER_' + this.options.id) || false;

            // CSV-DOWNLOAD
            this.csv = $('#exportCSV_' + this.options.id) || false;
            if (this.csv) this.csv.csvButton();

            // BULK-FORM
            this.form = $('#FORM_' + this.options.id) || false;
            this.selected = [];

            this.bulkbtn = $('.js-bulk[data-id="' + this.options.id + '"]');
            this.bulkbtn = (this.bulkbtn.length)? this.bulkbtn : false;

            this.bulkchoice = $('.js-bulk > .chosenize[data-id="' + this.options.id + '"]');
            this.bulkchoice = (this.bulkchoice.length)? this.bulkchoice : false;

            // SORTABLE COLUMNS
            this.sortables = this.table.find('.sortable');

            // FILTER
            this.filter_menu = $('.js-filter[data-id="' + this.options.id + '"]');
            this.filter_menu_btn = this.filter_menu.find('.dropdown-item');
            this.filter_reset_btn = this.filter_menu.find('.js-reset-btn');

            this.filterchoice = $('.js-filter > .chosenize[data-id="' + this.options.id + '"]');
            this.filterchoice = (this.filterchoice.length)? this.filterchoice : false;
            this.filter_reset = $('.js-filter-reset[data-id="' + this.options.id + '"]');

            // TEXT-SEARCH-FILTER
            this.textsearch_input = $('.js-text-search-field[data-id="' + this.options.id + '"]');
            this.textsearch_submit = $('.js-text-search-btn[data-id="' + this.options.id + '"]');

            this.textsearch_group = $('.js-text-search-group[data-id="' + this.options.id + '"]');
            if (this.textsearch_group.length)
            {
                this.set_search_key_btn = this.textsearch_group.find('.js-set-search-key');
            }

            this.boolean_filter = this.textsearch_group.find('.js-boolean-filter');


            // IPP-SWITCH
            this.ipp_menu = $('.js-ipp[data-target="' + this.options.id + '"]');
            this.ipp_menu_btn = this.ipp_menu.find('.dropdown-item');

						// PAGINATED LIST CONTEXT
            this.context = this.table.find('.tbody');

            // COPY-2-CLIPBOARD
            this.clipboardBtn = null;
            this.clipboard = false;

            // LIST-SORTER (on prop. item.position)
            this.positionBtn = null;

            // PAGER
            this.pagination = $('<ul/>', { 'class': 'pagination pagination-' + this.options.bsSize })
                .appendTo(this.element);

            this.pageItem = $('<li/>', { 'class': 'page-item' });
            this.pageLink= $('<a/>', { 'class': 'page-link', 'href': '#' })
                .appendTo(this.pageItem);

            this.prevBtn = this.pageItem.clone();
            this.prevBtn
                .find('.page-link')
                .addClass('page-link-prev')
                .append('<span class="' + this.options.iconPrevClass + '"/>');

            this.nextBtn = this.pageItem.clone();
            this.nextBtn
                .find('.page-link')
                .addClass('page-link-next')
                .append('<span class="' + this.options.iconNextClass + '"/>');

            this.pagerDots = this.pageItem.clone();
            this.pagerDots
                .addClass('pager-dots disabled')
                .find('.page-link')
                .text('...');

            this.pagerBtn = this.pageItem.clone();
            this.pagerBtns = [];

            this._on(this.sortables,        {click: '_sort'});
            this._on(this.filter_menu_btn,  {click: '_filter'});
            this._on(this.filter_reset_btn, {click: '_resetFilter'});
            this._on(this.filter_reset,     {click: '_resetFilter'});
            this._on(this.ipp_menu_btn,     {click: '_updateIpp'});

            this._on(this.form,
                {
                    selected: '_updateSelected',
                  deselected: '_updateSelected',
                      toggle: '_updateSelected'
                }
            );
            this._on(this.bulkbtn,            {click:  '_bulkAction'});
            this._on(this.bulkchoice,         {change: '_bulkAction'});
            this._on(this.filterchoice,       {change: '_filter'});

            this._on(this.textsearch_submit,  {click:  '_textSearchSubmit'});
            this._on(this.textsearch_input,   {change: '_textSearch'});

            this._on(this.set_search_key_btn, {click:  '_setTextSearchKey' });
            this._on(this.boolean_filter,     {click:  '_booleanFilter' });

            // Listen to API calls of other components, that might have affected the items-list
            // this._on($(window), {API: '_update'});
				},

				_init: function()
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _init`);

            if (null !== this.options.entity)
						{
								import ('./../entities/' + this.options.entity).then(
				                (list) => {
				                    this.list = new list.default(this.options);

                            // Check for Filter-Choice Presets
                            // f.e. if filter-settings are loaded from session and applied to the <select/> element.
                            if (this.filterchoice.length)
                            {
                                var
                                _filterPresets = this.filterchoice.find('option:selected'),
                                _list = this.list;

                                if (_filterPresets.length)
                                {
                                    _filterPresets.each(function(i,el)
                                        {
                                            _list.addFilterString($(el).attr('name') + '=' + $(el).attr('value'));
                                        }
                                    );

                                    // activate reset-button
                                    this.filter_reset
                                        .removeClass('disabled')
                                        .attr('aria-disabled', false)
                                        .prop('disabled', false);
                                }
                            }

                            if (this.boolean_filter.length)
                            {
                                var _list = this.list;
                                this.boolean_filter.each(function(i,el)
                                    {
                                        if ($(el).hasClass('active'))
                                        {
                                            _list.addFilterString($(el).attr('name') + '=' + $(el).data('value'));
                                        }
                                    }
                                );
                            }

														this._update(this.options.page);
				                }
				            )
				        ;
						}
				},

        _refresh: function()
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _refresh page ${this.list.currentPage}`);

            // Build pager buttons
            this.pagination.empty();

            // Remove ClipboardJS Events (@see: https://clipboardjs.com)
            if (this.clipboard)
            {
                this.clipboard.destroy();
            }

            if (this.list.pagesTotal > 1)
            {
                // ADD PREV-BUTTON
                var _prevBtn = this.prevBtn.clone();
                _prevBtn.appendTo(this.pagination);

                this._on(_prevBtn.find('.page-link'),{click:'_prev'});

                if (this.list.currentPage <= 1) {_prevBtn.addClass('disabled'); }

                // ADD PAGE-BUTTONS
                if (this.options.showPageButtons)
                {
                    var
                    _btnStatesArray = [],
                           _current = this.list.currentPage,
                             _total = this.list.pagesTotal,
                               _min = this.options.maxPageButtons,
                             _delta = Math.ceil(_min * .5),
                           _counter = 2;

                    // LIMIT AMOUNT OF DISPLAYED PAGE-BUTTONS (to @maxPageButtons)
                    var _hasHiddenButtons = (this.options.maxPageButtons < this.list.pagesTotal);
                    if (_hasHiddenButtons)
                    {
                        // calculate which page-buttons are hidden/visible and produce binary array
                        // w/ 'length = pagesTotal' where '0 = hidden' and '1 = visible'
                        // f.e.: (12) [1, 0, 0, 1, 1, *1* , 1, 1, 0, 0, 0, 1]
                        for (var i = 0; i < _total; i++)
                        {
                            var _p = (i + 1);
                            if (_p == 1 || _p == _total) {
                                _btnStatesArray[i] = 1;
                            } else
                            if ((_p <= (_current - _delta)) || (_p >= (_current + _delta) && _counter >= _min && _total > 2)) {
                                _btnStatesArray[i] = 0;
                            } else {
                                _btnStatesArray[i] = 1;
                                _counter++;
                            }
                        }
                    }
                    // console.log(_btnStatesArray);
                    // console.log(_hasHiddenButtons);
                    for (var i = 0; i < this.list.pagesTotal; i++)
                    {
                        var _p = (i + 1);
                        if ((_hasHiddenButtons && (_btnStatesArray[i] === 1)) || !_hasHiddenButtons)
                        {
                            this.pagerBtns[i] = this.pagerBtn.clone();
                            this.pagerBtns[i]
                                .find('.page-link')
                                .attr({ 'data-page': (i+1) })
                                .text(i+1);

                            if (this.list.currentPage == (i+1)) this.pagerBtns[i].addClass('active');

                            this.pagerBtns[i].appendTo(this.pagination);

                            this._on(this.pagerBtns[i].find('.page-link'),{click:'_goto'});
                        } else {
                            //  Hidden .pager-btns are replaced w/ .pager-dots.
                            //  Redundant .pager-dots are omitted by style-sheet declaration:
                            //  <css> .pager-dots + .pager-dots { display: none; } <css>
                            this.pagerBtns[i] = this.pagerDots.clone();
                            this.pagerBtns[i].appendTo(this.pagination);
                        }
                    }
                }

                // ADD NEXT-BUTTON
                var _nextBtn = this.nextBtn.clone();
                _nextBtn.appendTo(this.pagination);

                this._on(_nextBtn.find('.page-link'), {click:'_next'});

                if (this.list.currentPage >= this.list.pagesTotal) _nextBtn.addClass('disabled');
            }

            // FOOTER
            if (this.footer)
            {
                var
                _itemCounter = (this.footer.find('.item-counter').length)? this.footer.find('.item-counter') : false;

                if (!_itemCounter)
                {
                    _itemCounter = $('<span class="item-counter" />');
                    this.footer.prepend(_itemCounter);
                }
            }

            var
            _numFirstItemOfPage = ((this.list.currentPage - 1) * this.list.ipp) + 1,
            _numLastItemOfPage = _numFirstItemOfPage + this.list.ipp - 1;
            _numLastItemOfPage = (_numLastItemOfPage <= this.list.itemsTotal) ? _numLastItemOfPage : this.list.itemsTotal;

            var _itemCounterStr = _numFirstItemOfPage + ' - ' + _numLastItemOfPage + ' / ' + this.list.itemsTotal;
            _itemCounter.text(_itemCounterStr);

            // UPDATE ITEM-COUNTER
            if (this.counter) {
                // this.counter.addClass('show').text(this.list.itemsTotal);
                this.counter.addClass('show').text(_itemCounterStr);
            }

            this.selected = [];

            // CLIPBOARD-JS (@see: https://clipboardjs.com)
            this.clipboardBtn = this.context.find(this.options.clipboardSel);
            if (this.clipboardBtn.length)
            {
                if (DEBUG) console.log(this.clipboardBtn.length + ' clipboard buttons encountered');
                import ('clipboard').then(
                    (ClipboardJS) => {
                        if (DEBUG) console.log('clipboard.js loaded');
                        this.clipboard = new ClipboardJS.default(this.options.clipboardSel);
                        this.clipboard.on('success', function(e) {
                            console.log(e);
                            e.clearSelection();
                        });
                    }
                );
            }

            // SORT-LIST-BUTTONS
            this.positionBtn = this.context.find(this.options.positionBtnSel);
            if (this.positionBtn.length)
            {
                var _that = this;
                if (DEBUG) console.log(this.positionBtn.length + ' sort buttons encountered');
                this.positionBtn.on('click', function(e)
                {
                    // var _data = $(e.target).data();
                    _that._action(e);
                });
            }

            APP.wireBulkActionList(this.form);

            // this._trigger("change");
            if (DEBUG) console.log('===============================================');
				},

        /**
         *  @param string data
         */
				_loaded: function(html)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _loaded html`);

            var
            _that = this,
            _list = this.context.empty();

						$(html).each(function(i,el)
    						{
    								$(el)
                        .css({opacity:0})
                        .appendTo(_list);

                    var _btns = $(el).find('.js-list-item-btn');
                    if (_btns.length)
                    {
                        _that._on(_btns, {click: '_action'});
                    }
    						}
            );

            if (this.animation) this.animation.kill();

						this.animation = gsap.to(_list.find('.table-row'),
                {
                    duration: .1,
                     opacity: 1,
                     stagger: .05,
                       delay: .6
                }
            );

						this._refresh();

            // Update CSV-download-button W/ current filter settings
            if (this.csv)
            {
                var _str = '/api/load/access_codes_csv?pagination=false';
                // add filter params
                if (this.list.stickyFilter.length)
                {
                    $(this.list.stickyFilter).each(function(key, val) { _str += '&' + val; });
                }
                if (this.list.filter.length)
                {
                    $(this.list.filter).each(function(key, val) { _str += '&' + val; });
                }

                this.csv.csvButton('option', 'url', _str);
            }

            $(document).trigger('PAGER_EVENT', ['UPDATED', this.list]);
				},

        /**
         *  @param integer page
         */
				_update: function(page)
        {
            var _page = (typeof parseInt(page) === 'number') ? page : this.list.currentPage;

            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _update page ${_page}`);

            this.currentPage = _page;

            this.list.loadPage(_page, { onComplete: this._loaded.bind(this) });
				},

        /**
         *  @param object e
         */
        _action: function(e)
        {
            var
               _that = this,
            _trigger = $(e.delegateTarget),
               _data = _trigger.data(),
               _type = _data.method,
                 _id = _data.target;

            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _action event ${_type}`);
            if (DEBUG && VERBOSITY >= 2) console.log(_data);

            switch(_type) {
                case 'DELETE':
                    this.list.deleteItem(_id, {
                        onComplete: function(data, state) {
                            _that._update();
                            _that.element.trigger('deleted', [data]);
                            _that._trigger('_item_changed', _that.list, data);
                        }
                    });
                break;
                case 'PUT':
                    this.list.updateItem(_id, {
                        data: _data,
                        onComplete: function(data, state) {
                            _that._update();
                            _that.element.trigger('updated', [data]);
                            _that._trigger('_item_changed', _that.list, data);
                        }
                    });
                break;
            }

            $(document).trigger('PAGER_EVENT', [_type, _data]);
        },

        /**
         *  @param object e
         */
        _bulkAction: function(e)
        {
            // e.preventDefault();

            var
               _that = this,
            _trigger = $(e.delegateTarget),
         _targetForm = $('#FORM_' + _trigger.data('id')),
              _event = e.type,
               _data = _trigger.data(),
               _type = _data.method;

            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _bulkAction event ${_type}`);
            if (DEBUG && VERBOSITY >= 2) console.log(_data);

            // console.log(this.selected);

            if (_event == 'change')
            {  // prepare data object from an event dispatched by a choiceType input
                _data = {};
                _data[_trigger.attr('name')] = _trigger.val();
            }

            for(var i = 0; i < this.selected.length; i++)
            {
               var _target = this.context.find('[value="' + this.selected[i] + '"]').closest('.table-row');
               _target
                  .addClass('disabled warning')
                  .find('.btn, .link')
                  .addClass('disabled');
               // console.log(_target);
            }

            switch(_type) {
                case 'DELETE':
                    this.list.deleteItems(this.selected, {
                        onComplete: function(data, state) {
                            _that._update();
                            _that._trigger('_item_changed', _that.list, data);
                        }
                    });
                break;
                case 'PUT':
                    this.list.updateItems(this.selected, {
                        data: _data,
                        onComplete: function(data, state) {
                            _that._update();
                            _that._trigger('_item_changed', _that.list, data);
                        }
                    });
                break;
                case 'GET':
                    this.list.update({
                        data: _data,
                        onComplete: function(data, state) {
                            // _that._update();
                            console.log(data);
                        }
                    });
                break;
            }

            // Reset choice-type triggers
            if (_trigger[0].tagName === 'SELECT')
            {
                console.log(_trigger);
                _trigger.find('option').prop('selected', false).trigger('chosen:updated');
            }

            // Reset all item-checkboxes by setting '.toggle-all' checkbox to prop 'false'
            if (_targetForm.length)
            {
                _targetForm.find('.toggle-all').prop('checked', false).trigger('change');
            }

            $(document).trigger('PAGER_EVENT', [_type, _data]);
        },

        /**
         *  @param integer page
         */
        update: function(page = this.currentPage)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" update page ${page}`);
            this._update(page);
        },

        /**
         *  @param object filters
         */
        filter: function(filters)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" filter`);
            if (DEBUG && VERBOSITY >= 2) console.log(filters);

            if (this.list)
            {
                this.list.resetFilter();
                for (var key in filters)
                {
                    this.list.addFilterString(key + '=' + filters[key]);
                }
                this._update(this.currentPage);
            }
        },

        /**
         *  @param object e
         */
        _filter: function(e, params)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _filter event`);
            e.preventDefault();
            e.stopPropagation();

            if (e.delegateTarget.tagName === 'SELECT')
            {
                // FILTER w/ SELECT-BOX
                var
                _trigger = $(e.delegateTarget),
                _key = _trigger.attr('name') + '[]',
                _values = _trigger.val().toString().split(','),
                _selected = params.selected || false,
                _deselected = params.deselected || false,
                _filters = false;

                if (_selected)
                {
                    // Add Filter Item
                    var _key = _trigger.find('option[value="'+ _selected +'"]').attr('name');
                    _filters = this.list.addFilterString(_key + '=' + _selected);
                } else
                if (_deselected)
                {
                    // Remove Filter Item
                    var _key = _trigger.find('option[value="'+ _deselected +'"]').attr('name');
                    _filters = this.list.removeFilterString(_key + '=' + _deselected);
                }

                if (DEBUG && VERBOSITY >= 2) console.log(`filters: ${_filters}`);

                if (_filters.length) {
                    this.filter_reset
                        .removeClass('disabled')
                        .attr('aria-disabled', false)
                        .prop('disabled', false);
                } else {
                    this.filter_reset
                        .addClass('disabled')
                        .attr('aria-disabled', true)
                        .prop('disabled', true);
                }

                this._update(this.currentPage);

            } else {

                // FILTER w/ DROPDOWN
                var
                    _btn = $(e.delegateTarget),
                 _parent = _btn.closest('.btn-group'),
                _trigger = _parent.find('.dropdown-toggle'),
                 _filter = _btn.data('link'),
                  _label = _trigger.find('.js-filtered-label');

                  if (DEBUG && VERBOSITY >= 2) console.log(_filter);

                if (_filter)
                {
                    if(!_btn.hasClass('active'))
                    {
                        // @TODO: Allow for more than on filter at a time
                        this.list.resetFilter();
                        var _filters = this.list.addFilterString(_filter);

                        console.log(_filters);

                        _parent
                            .find('.active')
                            .removeClass('active');
                        _btn.addClass('active');
                        _trigger.addClass('active');

                        this.filter_reset_btn
                            .removeClass('disabled')
                            .attr('aria-disabled', false)
                            .prop('disabled', false);

                        _label.empty().text(_btn.text());

                        this._update(this.currentPage);
                    }
                }

                // hide dropdown manually since original event is suppressed by 'e.preventDefault()'
                _trigger.dropdown('hide');
            }

            return false;
        },

        _textSearch(e)
        {

            var
            _key = $(e.target).attr('name'),
            _val = $(e.target).val();

            if (_key.length && _val.length)
            {
                var _filters = this.list.addFilterString(_key + '=' + $(e.target).val());

                this.filter_reset
                    .removeClass('disabled')
                    .attr('aria-disabled', false)
                    .prop('disabled', false);

                this.textsearch_submit
                    .removeClass('disabled')
                    .attr('aria-disabled', false)
                    .prop('disabled', false);

                this._update(this.currentPage);

            } else {

                this.filter_reset.trigger('click');
            }
        },

        _textSearchSubmit(e)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _textsearchSubmit event`);

            e.preventDefault();
            e.stopPropagation();

            this.textsearch_input.trigger('change');
        },

        _setTextSearchKey(e)
        {
            var
            _trigger = $(e.target),
            _key = $(e.target).attr('name'),
            _label = $(e.target).text();

            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _setTextSearchKey event`);
            if (DEBUG) console.log(`_key: ${_key} / _label: ${_label}`);

            _trigger.parent().find('.active').removeClass('active');
            _trigger.addClass('active');

            this.textsearch_group.find('.js-text-search-field')
                .attr({
                    'placeholder': _label,
                    'name': _key
                });

            this.textsearch_input.val('');
            this.textsearch_submit.trigger('click');
        },

        _booleanFilter(e)
        {
            var
            _trigger = $(e.target),
            _key = $(e.target).attr('name'),
            _value = $(e.target).data('value');

            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _setTextSearchKey event`);
            if (DEBUG) console.log(`_key: ${_key} / _label: ${_value}`);

            var _active = _trigger.parent().find('.js-boolean-filter.active');
            this.list.removeFilterString(_active.attr('name') +'='+_active.data('value'));
            _active.removeClass('active');
            _trigger.addClass('active');

            this.list.addFilterString(_key + '=' + _value);

            this._update(this.currentPage);
        },

        /**
         *  @param object
         */
        _resetFilter: function(e)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _resetFilter event`);

            e.preventDefault();
            e.stopPropagation();

            var _list = this.list;

            _list.resetFilter();

            if (this.filter_menu.length)
            {
                this.filter_menu
                    .find('.active')
                    .removeClass('active')
                    .closest('.btn-group')
                    .find('.js-filtered-label')
                    .empty();
            }

            if (this.filter_reset_btn.length)
            {
                this.filter_reset_btn
                    .addClass('disabled')
                    .attr('aria-disabled', true)
                    .prop('disabled', true);
            }

            if (this.filter_reset.length)
            {
                this.filter_reset
                    .addClass('disabled')
                    .attr('aria-disabled', true)
                    .prop('disabled', true);
            }

            if (this.filterchoice.length)
            {
                this.filterchoice
                    .find('option')
                    .each(function(i,el){
                        $(el).prop('selected', false);
                    });

                    this.filterchoice.trigger('chosen:updated');
            }

            if (this.textsearch_input.length)
            {
                this.textsearch_input.val('');
            }

            if (this.boolean_filter.length)
            {
                this.boolean_filter.each(function(i,el)
                    {
                        if ($(el).hasClass('active'))
                        {
                            _list.addFilterString($(el).attr('name') + '=' + $(el).data('value'));
                        }
                    }
                );
            }

            this._update(this.currentPage);

            return false;
        },

        /**
         *  @param object
         */
        _updateIpp: function(e)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _updateIpp event`);

            e.preventDefault();
            e.stopPropagation();

            var
            _btn     = $(e.target),
            _trigger = this.ipp_menu.find('.dropdown-toggle');

            if (DEBUG && VERBOSITY >= 2) console.log(`PAGER: updateIpp (${e.type})`);

            this.ipp_menu
                .find('.active')
                .removeClass('active');

            this.list.ipp = _btn.data('value');

            _trigger
                .find('.label-txt')
                .html(_btn.html());

            _btn.addClass('active');

            // hide dropdown manually since original event is suppressed by 'e.preventDefault()'
            _trigger.dropdown('hide');

            this._update(this.currentPage);

            return false;
        },

        /**
         *  @param object e
         *  @param object data
         */
        _updateSelected: function(e, data)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _updateSelected event ${e.type}`);

            switch(e.type) {
                case 'selected':
                    this.selected.push(data);
                break;
                case 'deselected':
                    this.selected.splice( this.selected.indexOf(data), 1 );
                break;
                case 'toggle':
                    this.selected = data;
                break;
            }

            if (DEBUG && VERBOSITY >= 2) console.log(this.selected);
        },

        /**
         *  @param object e
         */
        _prev: function(e)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _prev event ${e.type}`);

            e.preventDefault();
            e.stopPropagation();
            var page = this.list.currentPage - 1;

            this._update(page);
        },

        /**
         *  @param object e
         */
        _next: function(e)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _next event ${e.type}`);

            e.preventDefault();
            e.stopPropagation();
            var page = this.list.currentPage + 1;

            this._update(page);
        },

        /**
         *  @param object e
         */
        _goto: function(e) {
            e.preventDefault();
            e.stopPropagation();
            var page = $(e.target).data('page');

            this._update(page);
        },

        /**
         *  @param object e
         */
        _sort: function(e)
        {
            if (DEBUG) console.log( `widgets.pager: "${this.options.entity}" _sort event ${e.type}`);

            e.preventDefault();
            e.stopPropagation();

            var
            _trigger = $(e.delegateTarget),
            _parent = _trigger.closest('.table'),
            _sortDirection = false, // default _sortDirection to false (= unsorted)
            _sorted = (_trigger.hasClass('sorted')); // and find out, if clicked column is already sorted?

            if (_sorted)
            {
                // if the column is sorted => find out current _sortDirection
                _sortDirection = (_trigger.hasClass('asc')) ? 'asc' : 'desc';
            }

            if (!_sorted)
            {
                // Phase 1) Not currently sorted => sort w/ direction descending (desc)
                _parent
                  .find('.sortable.sorted')
                  .removeClass('sorted asc desc');
                _trigger
                  .addClass('sorted')
                  .addClass('desc');

                _sortDirection = 'desc';
                _sorted = true;
            } else
            if (_sortDirection === 'desc')
            {
                // Phase 2) Currently sorted descending => toggle direction to ascending (asc)
                _trigger
                    .removeClass('desc')
                    .addClass('asc');

                _sortDirection = 'asc';
                _sorted = true;
            } else
            if (_sortDirection === 'asc')
            {
                // Phase 3) Currently sorted ascending => toggle to unsorted
                _trigger
                    .removeClass('asc')
                    .removeClass('sorted');

                _sortDirection = false;
                _sorted = false;
            }

            var _neworder = this.list.setOrder('order[' + _trigger.data('order-by') + ']', _sortDirection);

            if (DEBUG) console.log(`widgets.pager: "${this.options.entity}" new order ${_neworder}`);

            this._update(this.currentPage);

            return false;
        },

        /**!
         *  Get/Set list
         *
         *  @param EntityList list
         */
        getList()
        {
            return this.options.list;
        },
        setList(list)
        {
            this.options.list = list;
            return this;
        },

        /**!
         *  Get/Set currentPage
         *
         *  @param integer page
         */
        getCurrentPage()
        {
            return this.options.page;
        },
        setCurrentPage(page)
        {
            this.options.page = page;
            this.list.currentPage = this.options.page;
            return this;
        },
		});
});
