/**!
 *  CSV-BUTTON
 *
 *  @name			      toggle_box.js (js/widgets/csv_button.js)
 *
 *  @client	        NORDPOL-900
 *  @package        WIDGETS
 *  @description	  t.b.a.
 *  @copyright 	    (c) 2021 Ansgar Hiller <ansgar@weigelstein.de>
*/

$(function()
{
    $.widget('widgets.csvButton',
    {
				// default options
				options: {
						filename: 'csv-export',
						url: '/api/load/access_codes_csv?pagination=false'
				},

				// The constructor
        _create: function()
        {
						if (DEBUG) console.log('widgets.csvButton: _create');

						$.extend(this.options, this.element.data());
				},

				_init: function()
        {
            if (DEBUG) console.log('widgets.csvButton: _init');

						this._on(this.element,   {'click': '_clicked'});

				},

				_clicked: function(e)
				{
						if (DEBUG) console.log(`widgets.csvButton: (event) _clicked w/ options.url = ${this.options.url}`);

						$.ajax(
								{
													url: encodeURI(this.options.url),
												 type: 'GET',
									contentType: 'text/csv'
								}
						).then(
								(data, jqXHR, opt) => {

										var _contentType = opt.getResponseHeader('content-type');

										this._downloadCSV(data, this.options.filename + '.csv', _contentType);
								}
						);
				},

				/**!
				 *	downloadCSV
				 *	The download function takes a CSV string, the filename and mimeType as parameters
				 *
				 *	@param: content [string] -> csv-string
				 *	@param: fileName [string]
				 *	@param: mimeType [string]
				 */
				_downloadCSV: function(content, fileName, mimeType)
				{
						if (DEBUG) console.log(`widgets.csvButton: _downloadCSV w/ fileName = ${fileName} and mimeType = ${mimeType}`);

						var
						a = document.createElement('a');
						mimeType = mimeType || 'application/octet-stream';

						if (navigator.msSaveBlob)
						{
								// IE10
								navigator.msSaveBlob(new Blob([content],
										{
												type: mimeType
										}
								), fileName);

						} else if (URL && 'download' in a)
						{
								//html5 A[download]
								a.href = URL.createObjectURL(new Blob([content],
										{
												type: mimeType
										}
								));
								a.setAttribute('download', fileName);
								$('body')[0].appendChild(a);
								a.click();
								$('body')[0].removeChild(a);
						} else
						{
								location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
						}
				}
		});
});
