/**!
 *  CHOSENIZER
 *
 *  @name			      chosenizer.js (js/widgets/chosenizer.js)
 *
 *  @client	        NORDPOL-900
 *  @package        WIDGETS
 *  @description	  t.b.a.
 *  @copyright 	    (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
*/

import { gsap } from 'gsap';
import 'chosen-js';

$(function()
{
    $.widget('widgets.chosenizer',
    {
        // default options
        options: {
                        id: null,

                    entity: null,
            searchAddMode: false,
                     data: {},
                   method: 'POST',
             noResultText: null,
                    width: '100%',
        },

        // The constructor
        _create: function()
        {
            if ('SELECT' !== this.element[0].tagName) return false;

            $.extend(this.options, this.element.data());

            if (DEBUG) console.log(`widgets.chosenizer: "${this.options.entity}" _create`);

            this.chzn = null;   // chosen instance
            this.search = null; // search-field
            this.entityObject = false;
            this.new_items = [];

            var _that = this;

            if (null !== this.options.entity)
            {
                import ('./../entities/' + this.options.entity).then(
                        (item) => {
                            _that.entityObject = item;
                        }
                    )
                ;
            }

        },

        _init: function()
        {
            if (DEBUG) console.log(`widgets.chosenizer: "${this.options.entity}" _init`);

            this._on(this.element,{'chosen:ready':'_onReady'});

            this.element.chosen(
                    {
                        width: this.options.width,
                        inherit_select_classes: true,
                        disable_search_threshold: (this.options.searchAddMode)? 1 : 10,
                        no_results_text: (null !== this.options.noResultText)? this.options.noResultText : 'no results',
                    }
                )
            ;
        },

        _onReady: function(e, chzn)
        {
            if (DEBUG) console.log(`widgets.chosenizer: "${this.options.entity}" _onReady`);

            this.chzn = chzn;

            if (this.options.searchAddMode) this._initSearchAddMode();

            if (DEBUG) console.log('===============================================');
        },

        /*  CREATE NEW OPTION
         *  via '.chosen-search-input'
         */
        _initSearchAddMode: function()
        {
            if (DEBUG) console.log(`widgets.chosenizer: "${this.options.entity}" _initSearchAddMode`);

            var _that = this;

            this.search = $(this.chzn.chosen.container).find('.chosen-search-input');

            var _has_matching_results = true;

            this.search
                .on('keydown', function(e)
                    {
                        if (e.originalEvent.key === 'Enter' && !_has_matching_results)
                        {
                            var
                            _new_option_name = $(this).val();
                            _that.addNewOption(_new_option_name);
                        }
                    }
                )
            ;
            this.element
                .on('chosen:no_results', function(e,chzn)
                    {
                        console.log(e);
                        _has_matching_results = false;
                    }
                )
            ;
        },

        _refresh: function()
        {

        },

        addNewOption(name)
        {
            if (DEBUG) console.log(`widgets.chosenizer: "${this.options.entity}" addNewOption name "${name}"`);

            console.log('add option: ' + name);

            var
            _that = this,
            _data = {},
            _new_item = null;
            _data.name = name;
            $.extend(_data, this.options.data);

            if (typeof this.entityObject === 'object')
            {
                _data.onComplete = function(data)
                {
                    _that.element
                        .append('<option value="'+data.id+'" selected>'+data.name+'</option>');

                    _that.element
                        .trigger('chosen:close')
                        .trigger('chosen:updated')
                        .trigger('change');

                };

                var _new_item = new this.entityObject.default(null, _data);
            }
        }
    });
});
